import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { COMPRESSED, MEDIUM } from "../../../../../Shared/buttonSize";
import InputField from "../../Shared/inputField/inputField";
import { convertBase64 } from "../../../../../utils/convertToBase64";

const validExtensions = ["jpg", "jpeg", "png", "webp", "tiff"];

function FaceLivenessPopup({
  handleResponse,
  name,
  faceLivenessFetch,
  apiResponse,
}: any) {
  const errorObject = {
    consentError: false,
    faceError: false,
    wrongExtensionImage: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [faceMatch, setFaceMatch] = React.useState(false);
  const [userFaceMatchBase64, setUserFaceMatchBase64] = React.useState<string>(
    ""
  );
  const [loading, setLoading] = React.useState(false);
  const [fetchLoading, setFetchLoading] = React.useState(false);

  function handleCheck() {
    const consent = isChecked === true ? "Y" : "N";
    const face_match = userFaceMatchBase64;

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }

    if (faceMatch && face_match === "") {
      setError(() => ({
        ...error,
        faceError: true,
      }));

      return false;
    }

    setLoading(true);
    handleResponse({ consent, face_match }, setLoading);
  }

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!fileExtension || !validExtensions.includes(fileExtension)) {
      setError((prevError) => ({
        ...prevError,
        wrongExtensionImage: true,
      }));
      e.target.value = ""; // Clear the file input
      return;
    }

    setError((prevError) => ({
      ...prevError,
      wrongExtensionImage: false,
    }));

    let baseValue64 = (await convertBase64(file)) as string;

    // Remove the Base64 prefix if needed (e.g., data:image/jpeg;base64,)
    // const char = "base64,";
    // const index = baseValue64.indexOf(char);

    // if (index !== -1) {
    //   baseValue64 = baseValue64.substring(index + 1);
    // }

    baseValue64 = baseValue64?.split("base64,")?.[1];

    if (!baseValue64) return;

    setUserFaceMatchBase64(baseValue64);
  };

  const handleFetchWebhookData = () => {
    if (!apiResponse) return;

    let parsedResponse = apiResponse;
    if (typeof apiResponse === "string") {
      try {
        parsedResponse = JSON.parse(apiResponse);
      } catch (error) {
        console.log("Invalid JSON string");
        return null;
      }
    }

    const reqId = parsedResponse?.result?.request_id || null;
    if (!reqId) {
      return;
    }

    setFetchLoading(true);
    faceLivenessFetch(reqId, setFetchLoading);
  };

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>

      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <div className="pl-4 mt-4">
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(e: any) => {
              setFaceMatch(e.target.checked);
            }}
          />
          <p className={`${Styles.ackText} mb-4`}>Is face match required ?</p>
          {faceMatch && (
            <div className="d-flex flex-column mb-4 ">
              {userFaceMatchBase64 ? (
                <div className="d-flex flex-column justify-content-center">
                  <InputField
                    type="text"
                    value={userFaceMatchBase64}
                    purpose="forName"
                    className="w-50 p-2 mb-3"
                    style={{
                      border: "1px solid #e8e8e8",
                      borderRadius: 8,
                      outline: "none",
                    }}
                    disabled="true"
                    current="not-allowed"
                  />
                  <div className="w-50">
                    <Button
                      hoveredStyle={primaryButtonHoverStylePopup}
                      disabled={loading}
                      size={COMPRESSED}
                      style={primaryButtonStylePopup}
                      onClick={() => {
                        setUserFaceMatchBase64("");
                        setError((prevError) => ({
                          ...prevError,
                          wrongExtensionImage: false,
                        }));
                      }}
                    >
                      Select New Image
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className={`${Styles.inputContent} ml-2 d-flex mt-2 flex-column`}
                >
                  <label className={`${Styles.label}`}>Upload Image</label>
                  <input
                    id="id-input-file-estamp"
                    className={Styles.fileUpload}
                    type="file"
                    name="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => {
                      uploadImage(event);
                    }}
                  />
                  {error.wrongExtensionImage && (
                    <>
                      <ErrorMessage>
                        Please select correct Image! Only image files ( jpg,
                        jpeg, png ) are allowed.
                      </ErrorMessage>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="pl-4 pb-4 mt-4">
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(e: any) => {
              setIsChecked(e.target.checked);
            }}
          />
          <p className={`${Styles.ackText}`}>
            I hearby agree, to let zoop.one verify my data for verification
          </p>
          {error.consentError && (
            <ErrorMessage>Please provide consent!</ErrorMessage>
          )}
        </div>
        <div className={`${Styles.buttonGroup}`}>
          <Button
            id="id-submit-button-face-liveness"
            hoveredStyle={primaryButtonHoverStylePopup}
            disabled={loading}
            size={MEDIUM}
            isLoading={loading}
            style={primaryButtonStylePopup}
            onClick={() => {
              setTimeout(() => setError({ ...errorObject }), 2000);
              handleCheck();
            }}
          >
            Run Verification
          </Button>
          <div className="ml-4">
            {apiResponse && (
              <Button
                id="id-submit-fetch-face-liveness"
                hoveredStyle={primaryButtonHoverStylePopup}
                disabled={loading}
                size={MEDIUM}
                isLoading={fetchLoading}
                style={primaryButtonStylePopup}
                onClick={() => {
                  handleFetchWebhookData();
                }}
              >
                Fetch Response
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceLivenessPopup;
