import React, { useRef, useState, useEffect } from "react";
import Styles from "./rcSimilaritiesPopup.module.scss";

import InputField from "../../Shared/inputField/inputField";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import { IdsGenerator } from "../../../../../utils/idGenerator";
import Button from "../../../../../Shared/button/button";
import Select from "react-select";
import { days, months, expiryYears } from "../../../../../constants/data";
import { getYears } from "../../../../../utils/getYears";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";

const errorObject = {
  vehicleMakerDescError: false,
  vehicleMakerModelError: false,
  vehicleCubicCapacityError: false,
  vehicleFuelTypeError: false,
  vehicleSeatingCapacityError: false,
  rcInsuranceCompError: false,
  rcInsuranceCompValidationError: false,
  vehicleGrossWeightError: false,
  vehicleClassDescError: false,
  vehiclePermitNumberError: false,
  vehiclePermitIssuedError: false,
  vehiclePermitStartError: false,
  vehiclePermitExpiryError: false,
  vehiclePermitTypeError: false,
  vehicleBodyTypeDescError: false,
  vehicleManuMonthYearError: false,
  consentError: false,
};

const errorMessages = {
  vehicleMakerDescError: "*Maker description cannot be empty",
  vehicleMakerModelError: "*Vehicle model cannot be empty",
  vehicleCubicCapacityError: "*Vehicle capacity cannot be empty",
  vehicleFuelTypeError: "*Fuel description cannot be empty",
  vehicleSeatingCapacityError: "*Seating capacity cannot be empty",
  rcInsuranceCompError: "*Insurance comp. cannot be empty",
  rcInsuranceCompValidationError: "*Length cannot be greater than 100",
  vehicleGrossWeightError: "*Vehicle weight cannot be empty",
  vehicleClassDescError: "*Vehicle class cannot be empty",
  vehiclePermitNumberError: "*Vehicle RC permit cannot be empty",
  vehiclePermitIssuedError: "*Permit issued date cannot be empty",
  vehiclePermitStartError: "*Permit start date cannot be empty",
  vehiclePermitExpiryError: "*Permit expiry date cannot be empty",
  vehiclePermitTypeError: "*Vehicle permit type cannot be empty",
  vehicleBodyTypeDescError: "*Vehicle body type cannot be empty",
  vehicleManuMonthYearError: "*Manufacturing date cannot be empty",
  consentError: "*Please provide consent!",
};

const yearOptions = getYears(1950);

const RCSimilarities = ({ toastTimeout, handleResponse, name }: any) => {
  const serviceName = name.replaceAll(" ", "").toLowerCase();
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState(errorObject);
  const [checked, setIsChecked] = useState<boolean>(false);
  const [vehiclePermitIssuedDate, setVehiclePermitIssuedDate] = useState("");
  const [vehiclePermitIssuedMonth, setVehiclePermitIssuedMonth] = useState("");
  const [vehiclePermitIssuedYear, setVehiclePermitIssuedYear] = useState("");
  const [vehiclePermitStartDate, setVehiclePermitStartDate] = useState("");
  const [vehiclePermitStartMonth, setVehiclePermitStartMonth] = useState("");
  const [vehiclePermitStartYear, setVehiclePermitStartYear] = useState("");
  const [vehiclePermitExpiryDate, setVehiclePermitExpiryDate] = useState("");
  const [vehiclePermitExpiryMonth, setVehiclePermitExpiryMonth] = useState("");
  const [vehiclePermitExpiryYear, setVehiclePermitExpiryYear] = useState("");
  const [vehicleManufacturingMonth, setVehicleManufacturingMonth] = useState(
    ""
  );
  const [vehicleManufacturingYear, setVehicleManufacturingYear] = useState("");
  const vehicleMakerDescRef = useRef<any>("");
  const vehicleMakerModelRef = useRef<any>("");
  const vehicleCubicCapacityRef = useRef<any>("");
  const vehicleFuelTypeRef = useRef<any>("");
  const vehicleSeatingCapacityRef = useRef<any>("");
  const rcInsuranceComp = useRef<any>("");
  const vehicleGrossWeightRef = useRef<any>("");
  const vehicleClassDescRef = useRef<any>("");
  const vehiclePermitNumberRef = useRef<any>("");
  const vehiclePermitTypeRef = useRef<any>("");
  const vehicleBodyTypeDescRef = useRef<any>("");

  function checkEmptyValues() {
    if (
      vehicleCubicCapacityRef.current.value == "" ||
      vehicleFuelTypeRef.current.value === "" ||
      vehicleSeatingCapacityRef.current.value === "" ||
      rcInsuranceComp.current.value === "" ||
      vehicleGrossWeightRef.current.value === "" ||
      vehicleClassDescRef.current.value === "" ||
      vehiclePermitNumberRef.current.value === "" ||
      vehiclePermitIssuedDate === "" ||
      vehiclePermitIssuedMonth === "" ||
      vehiclePermitIssuedYear === "" ||
      vehiclePermitStartDate === "" ||
      vehiclePermitStartMonth === "" ||
      vehiclePermitStartYear === "" ||
      vehiclePermitExpiryDate === "" ||
      vehiclePermitExpiryMonth === "" ||
      vehiclePermitExpiryYear === "" ||
      vehiclePermitTypeRef.current.value === "" ||
      vehicleBodyTypeDescRef.current.value === "" ||
      vehicleManufacturingMonth === "" ||
      vehicleManufacturingYear === "" ||
      !checked
    ) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    try {
      if (
        errors.vehicleMakerDescError ||
        errors.vehicleMakerModelError ||
        errors.vehicleCubicCapacityError ||
        errors.vehicleFuelTypeError ||
        errors.vehicleSeatingCapacityError ||
        errors.rcInsuranceCompError ||
        errors.vehicleGrossWeightError ||
        errors.vehicleClassDescError ||
        errors.vehiclePermitNumberError ||
        errors.vehiclePermitIssuedError ||
        errors.vehiclePermitStartError ||
        errors.vehiclePermitExpiryError ||
        errors.vehiclePermitTypeError ||
        errors.vehicleBodyTypeDescError ||
        errors.vehicleManuMonthYearError ||
        errors.consentError ||
        checkEmptyValues()
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } catch (err) {
      setIsDisabled(true);
      toastTimeout(true, "error", (err as Error).message);
    }
  }, [
    vehicleCubicCapacityRef.current.value,
    vehicleCubicCapacityRef.current.value,
    vehicleFuelTypeRef.current.value,
    vehicleSeatingCapacityRef.current.value,
    rcInsuranceComp.current.value,
    vehicleGrossWeightRef.current.value,
    vehicleClassDescRef.current.value,
    vehiclePermitNumberRef.current.value,
    vehiclePermitIssuedDate,
    vehiclePermitIssuedMonth,
    vehiclePermitIssuedYear,
    vehiclePermitStartDate,
    vehiclePermitStartMonth,
    vehiclePermitStartYear,
    vehiclePermitExpiryDate,
    vehiclePermitExpiryMonth,
    vehiclePermitExpiryYear,
    vehiclePermitTypeRef.current.value,
    vehicleBodyTypeDescRef.current.value,
    vehicleManufacturingMonth,
    vehicleManufacturingYear,
    checked,
  ]);

  function handleCheck() {
    if (!vehicleMakerDescRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleMakerDescError: true,
      }));
      return false;
    } else if (!vehicleMakerModelRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleMakerModelError: true,
      }));
      return false;
    } else if (!vehicleCubicCapacityRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleCubicCapacityError: true,
      }));
      return false;
    } else if (!vehicleFuelTypeRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleFuelTypeError: true,
      }));
      return false;
    } else if (!vehicleSeatingCapacityRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleSeatingCapacityError: true,
      }));
      return false;
    } else if (!rcInsuranceComp.current.value) {
      setErrors((errors) => ({
        ...errors,
        rcInsuranceCompError: true,
      }));
      return false;
    } else if (rcInsuranceComp.current.value.trim().length > 100) {
      setErrors((errors) => ({
        ...errors,
        rcInsuranceCompValidationError: true,
      }));
      return false;
    } else if (!vehicleGrossWeightRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleGrossWeightError: true,
      }));
      return false;
    } else if (!vehicleClassDescRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleClassDescError: true,
      }));
      return false;
    } else if (!vehiclePermitNumberRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehiclePermitNumberError: true,
      }));
      return false;
    } else if (
      !vehiclePermitIssuedDate ||
      !vehiclePermitIssuedMonth ||
      !vehiclePermitIssuedYear
    ) {
      setErrors((errors) => ({
        ...errors,
        vehiclePermitIssuedError: true,
      }));
      return false;
    } else if (
      !vehiclePermitStartDate ||
      !vehiclePermitStartMonth ||
      !vehiclePermitStartYear
    ) {
      setErrors((errors) => ({
        ...errors,
        vehiclePermitStartError: true,
      }));
      return false;
    } else if (
      !vehiclePermitExpiryDate ||
      !vehiclePermitExpiryMonth ||
      !vehiclePermitExpiryYear
    ) {
      setErrors((errors) => ({
        ...errors,
        vehiclePermitExpiryError: true,
      }));
      return false;
    } else if (!vehiclePermitTypeRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehiclePermitTypeError: true,
      }));
      return false;
    } else if (!vehicleBodyTypeDescRef.current.value) {
      setErrors((errors) => ({
        ...errors,
        vehicleBodyTypeDescError: true,
      }));
      return false;
    } else if (!vehicleManufacturingMonth || !vehicleManufacturingYear) {
      setErrors((errors) => ({
        ...errors,
        vehicleManuMonthYearError: true,
      }));
      return false;
    } else if (!checked) {
      setErrors((errors) => ({
        ...errors,
        consentError: true,
      }));
      return false;
    }

    // Request Data Formation
    const vehicleMakerDesc = vehicleMakerDescRef.current.value;
    const vehicleModel = vehicleMakerModelRef.current.value;
    const vehicleCubicCapacity = vehicleCubicCapacityRef.current.value;
    const vehicleFuel = vehicleFuelTypeRef.current.value;
    const vehicleSeatingCapacity = vehicleSeatingCapacityRef.current.value;
    const vehicleInsuranceCompany = rcInsuranceComp.current.value;
    const vehicleGrossWeight = vehicleGrossWeightRef.current.value;
    const vehicleClass = vehicleClassDescRef.current.value;
    const vehicleRcPermit = vehiclePermitNumberRef.current.value;
    const vehicleRcPermitIssued =
      vehiclePermitIssuedDate +
      "-" +
      vehiclePermitIssuedMonth +
      "-" +
      vehiclePermitIssuedYear;
    const vehicleRcPermitType = vehiclePermitTypeRef.current.value;
    const vehicleRcPermitStartDate =
      vehiclePermitStartDate +
      "-" +
      vehiclePermitStartMonth +
      "-" +
      vehiclePermitStartYear;
    const vehicleBodyType = vehicleBodyTypeDescRef.current.value;
    const vehicleRcPermitExpiracyDate =
      vehiclePermitExpiryDate +
      "-" +
      vehiclePermitExpiryMonth +
      "-" +
      vehiclePermitExpiryYear;
    const vehicleManifacturingMonthAndYear =
      vehicleManufacturingMonth + "/" + vehicleManufacturingYear;

    setLoading(true);
    handleResponse(
      {
        vehicleMakerDesc,
        vehicleModel,
        vehicleCubicCapacity,
        vehicleFuel,
        vehicleSeatingCapacity,
        vehicleInsuranceCompany,
        vehicleGrossWeight,
        vehicleClass,
        vehicleRcPermit,
        vehicleRcPermitIssued,
        vehicleRcPermitType,
        vehicleRcPermitStartDate,
        vehicleBodyType,
        vehicleRcPermitExpiracyDate,
        vehicleManifacturingMonthAndYear,
      },
      setLoading
    );
  }

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-grow-1 align-items-center justify-content-between">
            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Maker Description
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle desc",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleMakerDescError: false });
                }}
                inputRef={vehicleMakerDescRef}
                isError={errors.vehicleMakerDescError}
                purpose="forRc"
              />
              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleMakerDescError && (
                  <ErrorMessage>
                    {`${errorMessages.vehicleMakerDescError}`}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Model
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle model",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleMakerModelError: false });
                }}
                inputRef={vehicleMakerModelRef}
                isError={errors.vehicleMakerModelError}
                purpose="forRc"
              />

              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleMakerModelError && (
                  <ErrorMessage>{`${errorMessages.vehicleMakerModelError}`}</ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Capacity
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle capacity",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleCubicCapacityError: false });
                }}
                inputRef={vehicleCubicCapacityRef}
                isError={errors.vehicleCubicCapacityError}
                purpose="forRc"
              />
              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleCubicCapacityError && (
                  <ErrorMessage>{`${errorMessages.vehicleCubicCapacityError}`}</ErrorMessage>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-grow-1 align-items-center justify-content-between">
            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Fuel Description
              </label>
              <InputField
                type="text"
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle fuel",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleFuelTypeError: false });
                }}
                inputRef={vehicleFuelTypeRef}
                isError={errors.vehicleFuelTypeError}
                purpose="forRc"
              />
              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleFuelTypeError && (
                  <ErrorMessage>
                    {`${errorMessages.vehicleFuelTypeError}`}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Seating Capacity
              </label>
              <InputField
                type="text"
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle seating capacity",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleSeatingCapacityError: false });
                }}
                inputRef={vehicleSeatingCapacityRef}
                isError={errors.vehicleSeatingCapacityError}
                purpose="forRc"
              />
              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleSeatingCapacityError && (
                  <ErrorMessage>
                    {`${errorMessages.vehicleSeatingCapacityError}`}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Insurance Company
              </label>
              <InputField
                type="text"
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle insurance",
                })}
                onChange={() => {
                  setErrors({ ...errors, rcInsuranceCompError: false });
                }}
                inputRef={rcInsuranceComp}
                isError={errors.rcInsuranceCompError}
                purpose="forRc"
              />
              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.rcInsuranceCompError && (
                  <ErrorMessage>{`${errorMessages.rcInsuranceCompError}`}</ErrorMessage>
                )}
                {errors.rcInsuranceCompValidationError && (
                  <ErrorMessage>{`${errorMessages.rcInsuranceCompValidationError}`}</ErrorMessage>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-grow-1 align-items-center justify-content-between">
            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Gross Weight
              </label>
              <InputField
                type="text"
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle weight",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleGrossWeightError: false });
                }}
                inputRef={vehicleGrossWeightRef}
                isError={errors.vehicleGrossWeightError}
                purpose="forRc"
              />

              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleGrossWeightError && (
                  <ErrorMessage>{`${errorMessages.vehicleGrossWeightError}`}</ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Class Description
              </label>
              <InputField
                type="text"
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle class",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleClassDescError: false });
                }}
                inputRef={vehicleClassDescRef}
                isError={errors.vehicleClassDescError}
                purpose="forRc"
              />

              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleClassDescError && (
                  <ErrorMessage>{`${errorMessages.vehicleClassDescError}`}</ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle RC Permit
              </label>
              <InputField
                type="text"
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle class",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehiclePermitNumberError: false });
                }}
                inputRef={vehiclePermitNumberRef}
                isError={errors.vehiclePermitNumberError}
                purpose="forRc"
              />

              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehiclePermitNumberError && (
                  <ErrorMessage>{`${errorMessages.vehiclePermitNumberError}`}</ErrorMessage>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-grow-1 align-items-center">
            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle RC Permit Issued Date
              </label>
              <div className="d-flex g-2">
                <div className="d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "date",
                    })}
                    options={days}
                    placeholder="DD"
                    onChange={(e: any) => {
                      setVehiclePermitIssuedDate(e.value);
                      setErrors({ ...errors, vehiclePermitIssuedError: false });
                    }}
                    styles={
                      !errors.vehiclePermitIssuedError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
                <div className="ml-2 d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "permit month",
                    })}
                    options={months}
                    placeholder="MMM"
                    onChange={(e: any) => {
                      setVehiclePermitIssuedMonth(e.label);
                      setErrors({ ...errors, vehiclePermitIssuedError: false });
                    }}
                    styles={
                      !errors.vehiclePermitIssuedError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
                <div className="ml-2 d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "permit year",
                    })}
                    options={yearOptions}
                    placeholder="YYYY"
                    onChange={(e: any) => {
                      setVehiclePermitIssuedYear(e.value);
                      setErrors({ ...errors, vehiclePermitIssuedError: false });
                    }}
                    styles={
                      !errors.vehiclePermitIssuedError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
              </div>

              <div
                className="position-absolute"
                style={{ bottom: -27, left: 0, width: "max-content" }}
              >
                {errors.vehiclePermitIssuedError && (
                  <ErrorMessage>
                    {`${errorMessages.vehiclePermitIssuedError}`}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 ml-5 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle RC Permit Type
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle RC permit type",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehiclePermitTypeError: false });
                }}
                inputRef={vehiclePermitTypeRef}
                isError={errors.vehiclePermitTypeError}
                purpose="forRc"
              />
              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehiclePermitTypeError && (
                  <ErrorMessage>{`${errorMessages.vehiclePermitTypeError}`}</ErrorMessage>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-grow-1 align-items-center ">
            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle RC Permit Start Date
              </label>
              <div className="d-flex g-2">
                <div className="d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "permit start date",
                    })}
                    options={days}
                    placeholder="DD"
                    onChange={(e: any) => {
                      setVehiclePermitStartDate(e.value);
                      setErrors({ ...errors, vehiclePermitStartError: false });
                    }}
                    styles={
                      !errors.vehiclePermitStartError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>

                <div className="ml-2 d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "permit start month",
                    })}
                    options={months}
                    placeholder="MMM"
                    onChange={(e: any) => {
                      setVehiclePermitStartMonth(e.label);
                      setErrors({ ...errors, vehiclePermitStartError: false });
                    }}
                    styles={
                      !errors.vehiclePermitStartError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>

                <div className="ml-2 d-flex flex-column">
                  <Select
                    id="id-input-year"
                    options={yearOptions}
                    placeholder="YYYY"
                    onChange={(e: any) => {
                      setVehiclePermitStartYear(e.value);
                      setErrors({ ...errors, vehiclePermitStartError: false });
                    }}
                    styles={
                      !errors.vehiclePermitStartError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
              </div>

              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehiclePermitStartError && (
                  <ErrorMessage>
                    {`${errorMessages.vehiclePermitStartError}`}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 ml-5 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Body Type
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: `${name} popup`,
                  id: "change",
                  sufix: "vehicle body type",
                })}
                onChange={() => {
                  setErrors({ ...errors, vehicleBodyTypeDescError: false });
                }}
                inputRef={vehicleBodyTypeDescRef}
                isError={errors.vehicleBodyTypeDescError}
                purpose="forRc"
              />

              <div
                className="position-absolute"
                style={{ bottom: -25, left: 0, width: "max-content" }}
              >
                {errors.vehicleBodyTypeDescError && (
                  <ErrorMessage>{`${errorMessages.vehicleBodyTypeDescError}`}</ErrorMessage>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-grow-1align-items-center">
            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle RC Permit Expiry Date
              </label>
              <div className="d-flex g-2">
                <div className="d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "permit expiry date",
                    })}
                    options={days}
                    placeholder="DD"
                    onChange={(e: any) => {
                      setVehiclePermitExpiryDate(e.value);
                      setErrors({ ...errors, vehiclePermitExpiryError: false });
                    }}
                    styles={
                      !errors.vehiclePermitExpiryError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
                <div className="ml-2 d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "permit expiry month",
                    })}
                    options={months}
                    placeholder="MMM"
                    onChange={(e: any) => {
                      setVehiclePermitExpiryMonth(e.label);
                      setErrors({ ...errors, vehiclePermitExpiryError: false });
                    }}
                    styles={
                      !errors.vehiclePermitExpiryError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
                <div className="ml-2 d-flex flex-column">
                  <Select
                    id="id-input-year"
                    options={expiryYears}
                    placeholder="YYYY"
                    onChange={(e: any) => {
                      setVehiclePermitExpiryYear(e.value);
                      setErrors({ ...errors, vehiclePermitExpiryError: false });
                    }}
                    styles={
                      !errors.vehiclePermitExpiryError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
              </div>

              <div
                className="position-absolute"
                style={{ bottom: -27, left: 0, width: "max-content" }}
              >
                {errors.vehiclePermitExpiryError && (
                  <ErrorMessage>
                    {`${errorMessages.vehiclePermitExpiryError}`}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div
              className={`${Styles.inputContent} d-flex flex-column mb-4 ml-5 position-relative`}
            >
              <label className={`${Styles.label} flex-grow-1`}>
                Vehicle Manufacturing Month/Year
              </label>
              <div className="d-flex g-2">
                <div className="d-flex flex-column">
                  <Select
                    id={IdsGenerator({
                      prefix: `${name} popup`,
                      id: "change",
                      sufix: "permit expiry month",
                    })}
                    options={months}
                    placeholder="MMM"
                    onChange={(e: any) => {
                      setVehicleManufacturingMonth(e.value);
                      setErrors({
                        ...errors,
                        vehicleManuMonthYearError: false,
                      });
                    }}
                    styles={
                      !errors.vehicleManuMonthYearError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
                <div className="ml-2 d-flex flex-column">
                  <Select
                    id="id-input-year"
                    options={yearOptions}
                    placeholder="YYYY"
                    onChange={(e: any) => {
                      setVehicleManufacturingYear(e.value);
                      setErrors({
                        ...errors,
                        vehicleManuMonthYearError: false,
                      });
                    }}
                    styles={
                      !errors.vehicleManuMonthYearError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
              </div>
              <div
                className="position-absolute"
                style={{ bottom: -27, left: 0, width: "max-content" }}
              >
                {errors.vehicleManuMonthYearError && (
                  <ErrorMessage>
                    {`${errorMessages.vehicleManuMonthYearError}`}
                  </ErrorMessage>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-2 pl-4 pb-3 d-flex flex-column position-relative">
          <input
            required
            type="checkbox"
            className="form-check-input"
            onChange={(e: any) => {
              setIsChecked(e.target.checked);
              setErrors({
                ...errors,
                consentError: false,
              });
            }}
            style={errors.consentError ? { border: "1px solid red" } : {}}
          />
          <p className="fs-1">
            I hearby agree, to let zoop.one verify my data for verification
          </p>

          <div className="position-absolute" style={{ bottom: 0, left: 0 }}>
            {errors.consentError && (
              <ErrorMessage>{`${errorMessages.consentError}`}</ErrorMessage>
            )}
          </div>
        </div>

        <div className={`${Styles.buttonGroup}`}>
          <Button
            id="id-submit-button-rc"
            hoveredStyle={primaryButtonHoverStylePopup}
            disabled={isDisabled}
            size={MEDIUM}
            isLoading={loading}
            style={primaryButtonStylePopup}
            onClick={() => {
              setTimeout(() => setErrors({ ...errorObject }), 3000);
              handleCheck();
            }}
          >
            Run Verification
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RCSimilarities;
