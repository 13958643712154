import React, { useEffect } from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import {
  validNameCheck,
  checkPAN,
  validAadhaarNumberLastFourDigit,
} from "../../../../../utils/validation";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import Select from "react-select";
import {
  days,
  dobYears,
  genderData,
  months,
} from "../../../../../constants/data";
import InputDropdown from "../../../../../Shared/input-dropdown/inputDropdown";
import { IdsGenerator } from "../../../../../utils/idGenerator";

function CkycServiceAdvancePopup({ handleResponse, name }: any) {
  const [day, setDay] = React.useState("");
  const [panDay, setPanDay] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [panMonth, setPanMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const [panYear, setPanYear] = React.useState("");
  const [gender, setGender] = React.useState("");
  const documentType: any = {
    PAN: "pan",
    AADHAAR: "aadhaar",
  };
  const dropdownData = ["PAN", "AADHAAR"];

  const [docType, setDocType] = React.useState("PAN");

  const errorObject = {
    panNumberError: false,
    consentError: false,
    dobDayError: false,
    dobMonthError: false,
    dobYearError: false,
    addharNumberError: "",
    customerNameError: "",
    genderError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const panNumberRef = React.useRef<any>("");
  const AadharNumberRef = React.useRef<any>("");
  const customerNameRef = React.useRef<any>("");

  useEffect(() => {
    if (
      docType === "AADHAAR" &&
      day &&
      month &&
      year &&
      gender &&
      AadharNumberRef.current.value &&
      customerNameRef.current.value
    ) {
      setIsDisabled(false);
    }
  }, [day, month, year, gender, AadharNumberRef, customerNameRef]);

  useEffect(() => {
    if (panNumberRef.current.value && panYear && panDay && panMonth) {
      setIsDisabled(false);
    }
  }, [panNumberRef, panDay, panMonth, panYear]);

  useEffect(() => {
    setError({ ...errorObject });
    setDay("");
    setGender("");
    setMonth("");
    setYear("");
    setIsDisabled(true);
  }, [docType]);

  function isValid() {
    setError({ ...errorObject });
    const panNumber = panNumberRef.current.value.trim().toUpperCase();
    if (
      docType === "PAN" &&
      checkPAN(panNumber) &&
      panDay &&
      panMonth &&
      panYear
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!checkPAN(panNumber)) {
      setError(() => ({
        ...error,
        panNumberError: true,
      }));
    }
    return setIsDisabled(true);
  }

  const ValidAadhaar = () => {
    const aadhar = AadharNumberRef.current.value.trim();

    if (aadhar === "") {
      setError(() => ({
        ...error,
        addharNumberError: "Aadhaar Number Should Not be Empty",
      }));
      setIsDisabled(true);
    } else if (!validAadhaarNumberLastFourDigit(aadhar)) {
      setError(() => ({
        ...error,
        addharNumberError: "Aadhaar Number is not valid",
      }));
      setIsDisabled(true);
    } else {
      setError(() => ({
        ...error,
        addharNumberError: "",
      }));
      if (
        docType === "AADHAAR" &&
        validAadhaarNumberLastFourDigit(aadhar) &&
        validNameCheck(customerNameRef.current.value) &&
        day &&
        month &&
        year &&
        gender
      ) {
        setError(errorObject);
        return setIsDisabled(false);
      }
    }
  };

  const validCustomerName = () => {
    const name = customerNameRef.current.value.trim();
    if (name === "") {
      setError((prevError) => ({
        ...prevError,
        customerNameError: "Customer Name Should Not be Empty",
      }));
      setIsDisabled(true);
    } else if (!validNameCheck(name)) {
      setError((prevError) => ({
        ...prevError,
        customerNameError: "Customer Name is not valid",
      }));
      setIsDisabled(true);
    } else {
      setError((prevError) => ({
        ...prevError,
        customerNameError: "",
      }));
      if (
        docType === "AADHAAR" &&
        validAadhaarNumberLastFourDigit(AadharNumberRef.current.value) &&
        validNameCheck(name) &&
        day &&
        month &&
        year &&
        gender
      ) {
        setError(errorObject);
        return setIsDisabled(false);
      }
    }
  };

  function isValidAadhar() {
    setError({ ...errorObject });
    const customerName = customerNameRef.current.value.trim();
    const aadharNumber = AadharNumberRef.current.value.trim();
    if (
      docType === "AADHAAR" &&
      validAadhaarNumberLastFourDigit(aadharNumber) &&
      validNameCheck(customerName) &&
      day &&
      month &&
      year &&
      gender
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    return setIsDisabled(true);
  }

  function handleCheck() {
    setError({ ...errorObject });
    const customerPanNumber =
      docType === "PAN" ? panNumberRef.current.value.toUpperCase() : "";

    const consent = isChecked === true ? "Y" : "N";

    const isAadhar = docType === "AADHAAR";
    const document_type = isAadhar ? documentType.AADHAAR : "";
    const customer_aadhaar_number = isAadhar
      ? AadharNumberRef.current.value
      : "";
    const customer_name = isAadhar ? customerNameRef.current.value : "";
    const customer_dob =
      docType === "PAN"
        ? `${panDay}-${panMonth}-${panYear}`
        : `${day}-${month}-${year}`;
    const customer_gender = isAadhar ? gender : "";

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);

    if (docType === "PAN") {
      handleResponse({ customerPanNumber, customer_dob, consent }, setLoading);
    } else if (docType === "AADHAAR") {
      handleResponse(
        {
          document_type,
          customer_aadhaar_number,
          customer_name,
          customer_dob,
          customer_gender,
          consent,
        },
        setLoading
      );
    }
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <div className={`${Styles.dropdown} mr-2 w-20`}>
              <label className={`${Styles.label}`}>Document Type</label>
              <InputDropdown
                id={IdsGenerator({
                  prefix: "ckyc advance popup",
                  id: "dropdown",
                  sufix: "document type",
                })}
                no_shadow="true"
                isValid={true}
                optionsArray={dropdownData}
                default_value={"PAN"}
                click={(value: any) => {
                  setDocType(value);
                }}
                className={Styles.inputDropdown}
              />
            </div>
          </div>

          {docType === "PAN" && (
            <>
              <label className={`${Styles.label} flex-grow-1`}>
                PAN Number
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: "ckyc advance popup",
                  id: "change",
                  sufix: "pan number",
                })}
                onChange={isValid}
                maxLength={10}
                inputRef={panNumberRef}
                isError={error.panNumberError}
                purpose="forPan"
              />
              {error.panNumberError && (
                <ErrorMessage>Please enter a valid Pan number</ErrorMessage>
              )}
              <br />
              <div className="d-flex flex-column mr-3">
                <label className={`${Styles.label}`}>DOB</label>
                <div className="">
                  <div className="mr-2 d-flex">
                    <div className="">
                      <Select
                        id={IdsGenerator({
                          prefix: "ckyc advance popup",
                          id: "select",
                          sufix: "pan date",
                        })}
                        onChange={(e: any) => {
                          setPanDay(e.value);
                          setError({ ...error, dobDayError: false });
                        }}
                        options={days}
                        styles={
                          !error.dobDayError
                            ? dropDownStyles.customStylesForDays
                            : dropDownStyles.customStylesForDaysError
                        }
                        placeholder="DD"
                      />
                    </div>
                    <div className="ml-2 d-flex flex-column">
                      <Select
                        id={IdsGenerator({
                          prefix: "ckyc advance popup",
                          id: "select",
                          sufix: "pan month",
                        })}
                        onChange={(e: any) => {
                          setPanMonth(e.value);
                          setError({ ...error, dobMonthError: false });
                        }}
                        options={months}
                        styles={
                          !error.dobMonthError
                            ? dropDownStyles.customStylesForMonths
                            : dropDownStyles.customStylesForMonthsError
                        }
                        placeholder="MMM"
                      />
                    </div>
                    <div className="ml-2 d-flex flex-column">
                      <Select
                        id={IdsGenerator({
                          prefix: "ckyc advance popup",
                          id: "select",
                          sufix: "pan year",
                        })}
                        onChange={(e: any) => {
                          setPanYear(e.value);
                          setError({ ...error, dobYearError: false });
                        }}
                        placeholder="YYYY"
                        options={dobYears}
                        styles={
                          !error.dobYearError
                            ? dropDownStyles.customStylesForYears
                            : dropDownStyles.customStylesForYearsError
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {docType === "AADHAAR" && (
            <>
              <div className="d-flex flex-column mb-4">
                <div className="d-flex flex-row">
                  <div style={{ width: "50%", marginRight: "10px" }}>
                    <label className={`${Styles.label}`}>
                      Aadhaar Number Last 4 Digit
                    </label>
                    <InputField
                      id={IdsGenerator({
                        prefix: "ckyc advance popup",
                        id: "change",
                        sufix: "aadhaar number",
                      })}
                      onChange={ValidAadhaar}
                      maxLength={4}
                      inputRef={AadharNumberRef}
                      isError={error.addharNumberError}
                      purpose="forPan"
                    />
                    {error.addharNumberError && (
                      <ErrorMessage>{error.addharNumberError}</ErrorMessage>
                    )}
                  </div>
                  <div style={{ width: "50%" }}>
                    <label className={`${Styles.label}`}>Customer Name</label>
                    <InputField
                      id={IdsGenerator({
                        prefix: "ckyc advance popup",
                        id: "change",
                        sufix: "aadhaar customer name",
                      })}
                      text="lowerCase"
                      onChange={validCustomerName}
                      inputRef={customerNameRef}
                      isError={error.customerNameError}
                      purpose="forVoter"
                    />
                    {error.customerNameError && (
                      <ErrorMessage>{error.customerNameError}</ErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="d-flex flex-column mr-3">
                  <label className={`${Styles.label}`}>DOB</label>
                  <div className="">
                    <div className="mr-2 d-flex">
                      <div className="">
                        <Select
                          id={IdsGenerator({
                            prefix: "ckyc advance popup",
                            id: "select",
                            sufix: "aadhaar date",
                          })}
                          onChange={(e: any) => {
                            setDay(e.value);
                            setError({ ...error, dobDayError: false });
                          }}
                          options={days}
                          styles={
                            !error.dobDayError
                              ? dropDownStyles.customStylesForDays
                              : dropDownStyles.customStylesForDaysError
                          }
                          placeholder="DD"
                        />
                      </div>
                      <div className="ml-2 d-flex flex-column">
                        <Select
                          id={IdsGenerator({
                            prefix: "ckyc advance popup",
                            id: "select",
                            sufix: "aadhaar month",
                          })}
                          onChange={(e: any) => {
                            setMonth(e.value);
                            setError({ ...error, dobMonthError: false });
                          }}
                          options={months}
                          styles={
                            !error.dobMonthError
                              ? dropDownStyles.customStylesForMonths
                              : dropDownStyles.customStylesForMonthsError
                          }
                          placeholder="MMM"
                        />
                      </div>
                      <div className="ml-2 d-flex flex-column">
                        <Select
                          id={IdsGenerator({
                            prefix: "ckyc advance popup",
                            id: "select",
                            sufix: "aadhaar year",
                          })}
                          onChange={(e: any) => {
                            setYear(e.value);
                            setError({ ...error, dobYearError: false });
                          }}
                          placeholder="YYYY"
                          options={dobYears}
                          styles={
                            !error.dobYearError
                              ? dropDownStyles.customStylesForYears
                              : dropDownStyles.customStylesForYearsError
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <label className={`${Styles.label}`}>Gender</label>

                  <Select
                    id={IdsGenerator({
                      prefix: "ckyc advance popup",
                      id: "select",
                      sufix: "aadhaar gender",
                    })}
                    onChange={(e: any) => setGender(e.value)}
                    options={genderData}
                    styles={
                      !error.genderError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "ckyc advance popup",
                  id: "check",
                  sufix: "consent",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);

                  docType === "AADHAAR" ? isValidAadhar() : isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I give my consent to download my KYC Records from the Central
                KYC Registry (CKYCR) by Fintelligence Data Science Pvt Ltd, only
                for the purpose of verification of my identity and address from
                the database of CKYCR Registry. I understand that my KYC Record
                includes my KYC Records/Personal information such as my name,
                address, date of birth, PAN number etc.
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id={IdsGenerator({
                prefix: "ckyc advance popup",
                id: "click",
                sufix: "run verification",
              })}
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CkycServiceAdvancePopup;
