//Component containing details of a particular app, like about Info of app, key settings,
// app settings,add key option.

// Hooks
import React, { useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTitle } from "../../../../Shared/useTitle";

// Styles
import Styles from "./appInfo.module.scss";
import { primaryButtonStyle } from "../../../../Shared/buttonStyles";
import { ACCENTCOLOR } from "../../../../Shared/colors";

// Components
import Loading from "../../../../Shared/loading/loading";
import AddServiceModal from "../addServices-pop-up/addServicesModal";
import Toast from "../../../../Shared/toast/toast";
import DeleteApiKey from "../delete-api-key/deleteApiKey";
import RenderTooltip from "../../../../Shared/tooltip/tooltip";
import ZpinMainPopup from "../../../../Shared/zpin-main-popup/zpinMainPopup";
import TrialButton from "../../trial-center/Shared/trialButton/trialButton";
import DataTable from "../../../../Shared/data-table/datatable";
import Switch from "../../../../Shared/toggle-switch/switch";

// constants
import Info from "./info-message/info";
import EmptyService from "../../../../assets/images/noServicesSubscribe.svg";
import Delete from "../../../../Shared/svg/delete";
import DeleteApp from "../delete-app/deleteApp";
import AddApiKey from "../add-api-key/addApiKey";
import { App } from "../../../../constants/app";

// Constants
import emptyStateKey from "../../../../assets/images/noApiKeyCreated.svg";

// Funtcions
import { callApi } from "../../../../api/fetch";
import { monthName } from "../../../../utils/getMonth";

function AppInfo() {
  //  Forbidden services for Product Level settings
  const forbiddenServices = ["merchantreports"];
  const history = useHistory();
  const orgId = localStorage.getItem("organisation_id");
  const { appId }: any = useParams();
  const [app, setApp] = React.useState<null | App>();
  const [
    toggleDeleteAppAuthPopUp,
    setToggleDeleteAppAuthPopUp,
  ] = React.useState(false);
  const [
    toggleOtpVerificationForAddServiceToApp,
    setToggleOtpVerificationForAddServiceToApp,
  ] = React.useState(false);
  const [
    toggleOtpVerificationForDisableAPIKey,
    setToggleOtpVerificationForDisableAPIKey,
  ] = React.useState(false);
  const [
    toggleOtpVerificationForEnableAPIKey,
    setToggleOtpVerificationForEnableAPIKey,
  ] = React.useState(false);
  const [relayId, setRelayId] = React.useState("");
  const [updatedServices, setUpdatedServices] = React.useState([]);
  const [toggleDeleteKeyPopUp, setToggleDeleteKeyPopUp] = React.useState(false);
  const [currentKeyName, setCurrentKeyName] = React.useState<any>("");
  const [toggleAddAppKey, setToggleAddAppKey] = React.useState(false);
  const [showAddService, setShowAddService] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [cardLoading, setCardLoading] = React.useState(false);
  const [apiKeyCardLoading, setApiKeyCardLoading] = React.useState(false);
  const [otpVerificationLoading, setOtpVerificationLoading] = React.useState(
    false
  );
  const [zpinExists, setZpinExists] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [toggleResetOtp, setToggleResetOtp] = React.useState(false);
  const app_message = {
    TEST:
      "Integration with this app can only be used for testing products and will deduct trial credits.",
    LIVE:
      "Integration with this app will be done on live environment and incur wallet balance deductions.",
  };

  useEffect(() => {
    async function checkForZpin() {
      try {
        const res = await callApi(`/zpin/${orgId}`, {
          method: "GET",
        });
        if (res.data !== null) setZpinExists(true);
      } catch (error) {
        setZpinExists(false);
      }
    }
    checkForZpin();
  }, [orgId]);

  function getCreatedAndUpdatedDate(createdAt: number, updatedAt: number) {
    const createdDate = new Date(Number(createdAt));
    const updatedDate = new Date(Number(updatedAt));
    return {
      createdDate: `${monthName(
        createdDate.getMonth()
      )} ${createdDate.getDate()}, ${createdDate.getFullYear()}`,
      updatedDate: `${monthName(
        updatedDate.getMonth()
      )} ${updatedDate.getDate()}, ${updatedDate.getFullYear()}`,
    };
  }

  const getAppById = useCallback(async () => {
    setLoading(true);
    try {
      await callApi(`/app/${appId}`, {
        method: "GET",
      })
        .then((response: any) => {
          const date = new Date(Number(response.createdAt));
          //  Filter products from Product level settings
          response.service_access = response.service_access.filter(
            (service_access: any) =>
              !forbiddenServices.includes(
                service_access.name.replaceAll(" ", "").toLowerCase()
              )
          );
          setApp(response);
          setApp((response: any) => ({
            ...response,
            createdAt: `${monthName(
              date.getMonth()
            )} ${date.getDate()}, ${date.getFullYear()}`,
            keys: response.keys.map((key: any) => {
              const { createdDate, updatedDate } = getCreatedAndUpdatedDate(
                key.createdAt,
                key.updatedAt
              );
              return {
                ...key,
                createdAt: createdDate,
                updatedAt: updatedDate,
              };
            }),
          }));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toastTimeout(true, "error", err.message);
          history.replace("/application/manage-apps");
          // TODO: add sentry error
        });
    } catch (err) {
      console.log(err);
    }
  }, [appId, history]);

  useEffect(() => {
    getAppById();
  }, [getAppById]);

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  async function sendOtp(purpose: string) {
    try {
      const res = await callApi("/events?action=send_otp", {
        method: "POST",
        data: {
          purpose: purpose,
        },
      });
      const { relay_id } = res;
      setRelayId(relay_id);
      return relay_id;
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  async function handleEnablingAPIKeyWithZpin(
    zpin: string,
    otp: string,
    setInvalidOTPError: any
  ) {
    setOtpVerificationLoading(true);
    try {
      let res: any;
      if (zpin) {
        res = await callApi(`/app/${appId}?action=enable_app_key`, {
          method: "PUT",
          data: {
            keyName: currentKeyName,
            orgId: orgId,
            zpin: zpin,
          },
        });
      } else if (otp) {
        res = await callApi(`/app/${appId}?action=enable_app_key`, {
          method: "PUT",
          data: {
            keyName: currentKeyName,
            relayId: relayId,
            otp: otp,
          },
        });
      }
      setOtpVerificationLoading(false);
      setToggleResetOtp(false);
      setToggleOtpVerificationForEnableAPIKey(false);
      setApiKeyCardLoading(true);
      setApp((app: any) => ({
        ...app,
        keys: res.keys.map((key: any) => {
          const { createdDate, updatedDate } = getCreatedAndUpdatedDate(
            key.createdAt,
            key.updatedAt
          );
          return {
            ...key,
            createdAt: createdDate,
            updatedAt: updatedDate,
          };
        }),
      }));
      setApiKeyCardLoading(false);
    } catch (err) {
      setOtpVerificationLoading(false);
      setInvalidOTPError();
    }
  }

  async function handleDeactivateKeyWithZpin(
    zpin: string,
    otp: string,
    setInvalidOTPError: any
  ) {
    setOtpVerificationLoading(true);
    try {
      let res: any;
      if (zpin) {
        res = await callApi(`/app/${appId}?action=disable_app_key`, {
          method: "PUT",
          data: {
            keyName: currentKeyName,
            orgId: orgId,
            zpin: zpin,
          },
        });
      } else if (otp) {
        res = await callApi(`/app/${appId}?action=disable_app_key`, {
          method: "PUT",
          data: {
            keyName: currentKeyName,
            relayId: relayId,
            otp: otp,
          },
        });
      }
      setOtpVerificationLoading(false);
      setToggleResetOtp(false);
      setToggleOtpVerificationForDisableAPIKey(false);
      setApiKeyCardLoading(true);
      setApp((app: any) => ({
        ...app,
        keys: res.keys.map((key: any) => {
          const { createdDate, updatedDate } = getCreatedAndUpdatedDate(
            key.createdAt,
            key.updatedAt
          );
          return {
            ...key,
            createdAt: createdDate,
            updatedAt: updatedDate,
          };
        }),
      }));
      setApiKeyCardLoading(false);
    } catch (err) {
      setOtpVerificationLoading(false);
      setInvalidOTPError();
    }
  }

  async function handleAddServiceWithZpin(
    enabledAppServices: any,
    zpin: string,
    otp: string,
    setInvalidOTPError: any
  ) {
    const arrayOfIds = enabledAppServices.map((service: any) => {
      const { _id } = service;
      return _id;
    });
    setOtpVerificationLoading(true);
    if (zpin) {
      await callApi(`/app/${app?._id}?action=app_update_subscribed_product`, {
        method: "PUT",
        data: {
          productIds: arrayOfIds,
          orgId: orgId,
          zpin: zpin,
        },
      })
        .then((res) => {
          setOtpVerificationLoading(false);
          setToggleResetOtp(false);
          setToggleOtpVerificationForAddServiceToApp(false);
          setCardLoading(true);
          setApp((app: any) => ({
            ...app,
            service_access: res.service_access,
          }));
          setCardLoading(false);
        })
        .catch(() => {
          setOtpVerificationLoading(false);
          // setToggleResetOtp(true);
          // toastTimeout(true, "error", err.message);
          // TODO: add sentry error
          setInvalidOTPError();
        });
    } else if (otp) {
      await callApi(`/app/${app?._id}?action=app_update_subscribed_product`, {
        method: "PUT",
        data: {
          productIds: arrayOfIds,
          relayId: relayId,
          otp: otp,
        },
      })
        .then((res) => {
          setOtpVerificationLoading(false);
          setToggleResetOtp(false);
          setToggleOtpVerificationForAddServiceToApp(false);
          setCardLoading(true);
          setApp((app: any) => ({
            ...app,
            service_access: res.service_access,
          }));
          setCardLoading(false);
        })
        .catch(() => {
          setOtpVerificationLoading(false);
          // setToggleResetOtp(true);
          // toastTimeout(true, "error", err.message);
          // TODO: add sentry error
          setInvalidOTPError();
        });
    }

    return;
  }

  function handleDeleteKey(value: any) {
    setApp((app: any) => ({
      ...app,
      keys: value,
    }));
    setToggleDeleteKeyPopUp(false);
    return;
  }

  function handleDeleteKeyCancel() {
    setToggleDeleteKeyPopUp(false);
    return;
  }

  function handelDeleteAuthCancel() {
    setToggleDeleteAppAuthPopUp(false);
    console.log("Delete App Auth cancel");
    return;
  }

  function checkKeyStatus() {
    const statusArray: any = [];
    if (app?.keys.length) {
      app?.keys.map((key: any) => {
        const { status } = key;
        statusArray.push(status);
      });
      if (statusArray.includes("ENABLED")) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function handelAddAppKey(value: any) {
    setApp((app: any) => ({
      ...app,
      keys: value,
    }));

    return;
  }
  useTitle("Zoop Customer Platform | App Info ");

  const tableHeaders = [
    {
      name: "Key name",
      className: Styles.headerContainer,
    },
    {
      name: "Created At",
      className: Styles.headerContainer,
    },
    {
      name: "Updated At",
      className: Styles.headerContainer,
    },
    {
      name: "Status",
      className: Styles.headerContainer,
    },
    {
      name: "",
      className: Styles.headerContainer + " " + Styles.deleteColumn,
    },
  ];

  return (
    <div>
      {toast.show ? (
        <Toast
          type={toast.type}
          message={toast.message}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      ) : null}
      {loading ? (
        <div
          style={{
            height: "80vh",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div className="p-3 mx-3">
          <div className="d-flex align-items-center py-2 px-2">
            <div className="py-2">
              <Info
                message={app?.is_test_app ? app_message.TEST : app_message.LIVE}
              />
            </div>
            <div className={"ml-auto px-4"}>
              {app?.service_access.length === 0 ? (
                <RenderTooltip
                  placement="bottom"
                  title="Add products to this app to enable settings"
                >
                  <div
                    className={`${Styles.appSettings}`}
                    style={{ cursor: "no-drop", opacity: 0.5 }}
                  >
                    App Settings
                  </div>
                </RenderTooltip>
              ) : (
                <div
                  id="app-info-setting"
                  onClick={() => {
                    history.push(
                      `/application/manage-apps/${app?._id}/settings`,
                      {
                        id: app?._id,
                        // ips: app?.ip_whitelisted,
                        // services: app?.service_access,
                      }
                    );
                  }}
                  className={`${Styles.primaryLink}`}
                >
                  App Settings
                </div>
              )}
            </div>
            <div className="pl-1 pr-1">
              {app?.service_access.length === 0 ? (
                <RenderTooltip
                  placement="bottom"
                  title="Add products to this app to Add an API key"
                >
                  <div
                    style={primaryButtonStyle}
                    className={`d-flex align-items-center justify-content-center ${Styles.btn}`}
                  >
                    <p className={`mb-0 ${Styles.btnText}`}>Add Key</p>
                  </div>
                </RenderTooltip>
              ) : (
                <div
                  className={Styles.primaryLink}
                  id="app-info-add-key"
                  onClick={() => setToggleAddAppKey(true)}
                >
                  Add Key
                </div>
              )}
            </div>
            {app?.is_test_app && (
              <div className="ml-4">
                <TrialButton linkTo="/application/trial-center">
                  Trial
                </TrialButton>
              </div>
            )}
          </div>

          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-lg-4 p-2">
                <div
                  className={`${Styles.card} ${Styles.cardAnimation} pl-2 pb-3`}
                >
                  <div className={`p-3 ${Styles.cardHeader}`}>
                    <p className={"mb-0 px-2 py-2 card_title_bold_text"}>
                      App Details
                    </p>
                  </div>
                  <div className="px-3 pb-3">
                    <div className="d-flex align-items-start py-2 mt-2">
                      <div className={`${Styles.keyWidth} px-2`}>
                        <p className={`mb-0 ${Styles.key}`}>App ID:</p>
                      </div>
                      <div className={`px-2 ${Styles.valueWidth}`}>
                        <p className={`mb-0 ${Styles.value}`}>{app?._id}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start py-2 mt-2">
                      <div className={`${Styles.keyWidth} px-2`}>
                        <p className={`mb-0 ${Styles.key}`}>Name:</p>
                      </div>
                      <div className={`px-2 ${Styles.valueWidth}`}>
                        <p className={`mb-0 ${Styles.value}`}>{app?.name}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start py-2 mt-2">
                      <div className={`${Styles.keyWidth} px-2`}>
                        <p className={`mb-0 ${Styles.key}`}>Created At:</p>
                      </div>
                      <div className={`px-2 ${Styles.valueWidth}`}>
                        <p className={`mb-0 ${Styles.value}`}>
                          {app?.createdAt}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start py-2 mt-2">
                      <div className={`${Styles.keyWidth} px-2`}>
                        <p className={`mb-0 ${Styles.key}`}>Keys:</p>
                      </div>
                      <div className={`px-2 ${Styles.valueWidth}`}>
                        <p className={`mb-0 ${Styles.value}`}>
                          {app?.keys.length}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start py-2 mt-2">
                      <div className={`${Styles.keyWidth} px-2`}>
                        <p className={`mb-0 ${Styles.key}`}>Products:</p>
                      </div>
                      <div className={`px-2 ${Styles.valueWidth}`}>
                        <p className={`mb-0 ${Styles.value}`}>
                          {app?.service_access.length}
                        </p>
                      </div>
                    </div>
                    {!app?.is_test_app && (
                      <div className="d-flex justify-content-end">
                        <div
                          style={{ cursor: "pointer" }}
                          id="app-info-delete-app"
                          onClick={() => {
                            if (checkKeyStatus()) {
                              toastTimeout(
                                true,
                                "error",
                                "Disable all API keys before deleting the app."
                              );
                            } else {
                              setToggleDeleteAppAuthPopUp(true);
                            }
                          }}
                        >
                          <Delete className={Styles.redDeleteIcon} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 p-2">
                {cardLoading ? (
                  <div
                    className={`d-flex align--items-center justify-content-center ${Styles.loadingCard}`}
                  >
                    <Loading loadingColor={ACCENTCOLOR} />
                  </div>
                ) : (
                  <div
                    className={`${Styles.card} ${Styles.cardAnimation} pb-3`}
                  >
                    <div
                      className={`pb-1 pt-3 pr-3 pl-3 ${Styles.cardHeader} ${Styles.productLevelSetting}`}
                    >
                      <div className="d-flex align-items-center">
                        <p className={"mb-0 px-2 py-2 card_title_bold_text"}>
                          Product Level Settings
                        </p>
                        <div className="ml-auto px-2">
                          <div
                            className={Styles.primaryLink}
                            id="app-info-manage-product"
                            onClick={() => setShowAddService(true)}
                          >
                            Manage Products
                          </div>
                        </div>
                      </div>
                    </div>
                    {app?.service_access.length === 0 ? (
                      <div
                        className={`${Styles.emptyStateDiv} d-flex align-items-center justify-content-center`}
                      >
                        <div className={Styles.noProductContainer}>
                          <img
                            src={EmptyService}
                            alt="no_service_found"
                            className={Styles.emptyStateIllustration}
                          />
                          <p className={`mb-1 pt-2 overline_strong_text`}>
                            No products associated
                          </p>
                          <p className="overline_text">
                            Link a product to get started.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center flex-wrap p-3"
                        style={{
                          maxHeight: "250px",
                          overflow: "auto",
                        }}
                      >
                        {app?.service_access.map((service: any) => {
                          return (
                            <div className={"p-1"} key={service._id}>
                              <div
                                className={`${Styles.addedService} px-3 py-2`}
                              >
                                <p className={`mb-0 ${Styles.serviceName}`}>
                                  {service.name}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row p-2 mt-1">
              <DataTable
                headers={tableHeaders}
                loading={apiKeyCardLoading}
                rows={app?.keys}
                headerTitle={"Manage Keys"}
                pagination={false}
                purpose=""
                headerClass={Styles.headerContainer}
                tableHeader={
                  <>
                    <p
                      className={`mb-0 card_title_bold_text ${Styles.headerTitle}`}
                    >
                      {"Manage Keys"}
                    </p>
                  </>
                }
                emptyState={
                  <div className={Styles.noProductContainer}>
                    <img
                      src={emptyStateKey}
                      alt="no_service_found"
                      className={Styles.emptyStateIllustration}
                    />
                    <p className={`mb-1 pt-2 overline_strong_text`}>
                      No active key associated
                    </p>
                    <p className="overline_text">
                      Click Add Key to generate a new API key...
                    </p>
                  </div>
                }
              >
                {app?.keys.map((key: any, index: number) => {
                  return (
                    <tr
                      key={`${key.key_name} ${key.createdAt}`}
                      id={`table-row-${index}-${key.key_name
                        .split(" ")
                        .join("-")}`}
                      className={Styles.tableRow}
                    >
                      <td className={`${Styles.tableBodyText}`}>
                        {key.key_name}
                      </td>
                      <td className={`${Styles.tableBodyText}`}>
                        {key.createdAt}
                      </td>
                      <td className={`${Styles.tableBodyText}`}>
                        {key.updatedAt}
                      </td>
                      <td className={`${Styles.tableBodyText}`}>
                        <div className="d-flex align-items-center">
                          <Switch
                            positiveLabel="Enable"
                            negativeLabel="Disable"
                            id={`disable-enable-switch-appkey-${index}`}
                            checked={key.status === "ENABLED"}
                            onChange={() => {
                              if (key.status === "ENABLED") {
                                setCurrentKeyName(key.key_name);
                                if (!zpinExists)
                                  sendOtp(
                                    `OTP verification required for ${key.key_name}`
                                  );
                                setToggleOtpVerificationForDisableAPIKey(true);
                                return;
                              }
                              setCurrentKeyName(key.key_name);
                              if (!zpinExists)
                                sendOtp(
                                  `OTP verification required for ${key.key_name}`
                                );
                              setToggleOtpVerificationForEnableAPIKey(true);
                            }}
                          ></Switch>
                        </div>
                      </td>
                      <td
                        className={`${Styles.tableBodyText} ${Styles.deleteColumn} text-center`}
                      >
                        <div
                          id="app-info-delete-key-1"
                          className={Styles.showWhiteDeleteIcon}
                        >
                          <Delete
                            id="delete-button-app-info"
                            className={Styles.whiteDeleteIcon}
                          />
                        </div>
                        <div
                          className={Styles.showRedDeleteIcon}
                          id="app-info-delete-key-2"
                          onClick={() => {
                            setCurrentKeyName(key.key_name);
                            if (key.status === "ENABLED") {
                              toastTimeout(
                                true,
                                "error",
                                "Disable the key before deleting one."
                              );
                              return;
                            }
                            setToggleDeleteKeyPopUp(true);
                          }}
                        >
                          <Delete className={Styles.redDeleteIcon} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </div>
          </div>
        </div>
      )}
      {toggleDeleteKeyPopUp ? (
        <DeleteApiKey
          onSubmit={(value: any) => handleDeleteKey(value)}
          onCancel={handleDeleteKeyCancel}
          keyName={currentKeyName}
          appId={app?._id}
        />
      ) : null}
      {toggleDeleteAppAuthPopUp ? (
        <DeleteApp onCancel={handelDeleteAuthCancel} appId={app?._id} />
      ) : null}
      {toggleAddAppKey ? (
        <AddApiKey
          onSubmit={(value: any) => handelAddAppKey(value)}
          onCancel={() => setToggleAddAppKey(false)}
          appId={app?._id}
        />
      ) : null}
      {showAddService ? (
        <AddServiceModal
          enabledService={app?.service_access}
          onSubmit={(enabledAppServices: any) => {
            setUpdatedServices(enabledAppServices);
            setShowAddService(false);
            if (!zpinExists) sendOtp("Managing Service");
            setToggleOtpVerificationForAddServiceToApp(true);
          }}
          onCancel={() => setShowAddService(false)}
        />
      ) : null}
      {toggleOtpVerificationForAddServiceToApp && (
        <ZpinMainPopup
          zpinExists={zpinExists}
          toggleResetOtp={toggleResetOtp}
          setToggleResetOtp={(value: boolean) => setToggleResetOtp(value)}
          resendOtp={(purpose: string = "Managing Services") =>
            sendOtp(purpose)
          }
          isLoading={otpVerificationLoading}
          purpose="Managing Services"
          onVerifyOtp={(zpin: string, otp: string, setInvalidOtpError: any) => {
            handleAddServiceWithZpin(
              updatedServices,
              zpin,
              otp,
              setInvalidOtpError
            );
          }}
          onCancel={() => setToggleOtpVerificationForAddServiceToApp(false)}
          updateZpinStatus={() => setZpinExists(true)}
          handleZpinAuth={(
            zpin: string,
            otp: string,
            setInvalidZpinError: any
          ) =>
            handleAddServiceWithZpin(
              updatedServices,
              zpin,
              otp,
              setInvalidZpinError
            )
          }
        />
      )}
      {toggleOtpVerificationForDisableAPIKey && (
        <ZpinMainPopup
          zpinExists={zpinExists}
          toggleResetOtp={toggleResetOtp}
          setToggleResetOtp={(value: boolean) => setToggleResetOtp(value)}
          resendOtp={(purpose: string = "Disabling the APP key") =>
            sendOtp(purpose)
          }
          isLoading={otpVerificationLoading}
          purpose="Disabling the APP key"
          onVerifyOtp={(zpin: string, otp: string, setInvalidOTPError: any) => {
            handleDeactivateKeyWithZpin(zpin, otp, setInvalidOTPError);
          }}
          onCancel={() => setToggleOtpVerificationForDisableAPIKey(false)}
          handleZpinAuth={handleDeactivateKeyWithZpin}
          updateZpinStatus={() => setZpinExists(true)}
        />
      )}
      {toggleOtpVerificationForEnableAPIKey && (
        <ZpinMainPopup
          zpinExists={zpinExists}
          toggleResetOtp={toggleResetOtp}
          setToggleResetOtp={(value: boolean) => setToggleResetOtp(value)}
          resendOtp={(purpose: string = "Enabling the APP key") =>
            sendOtp(purpose)
          }
          isLoading={otpVerificationLoading}
          purpose="Enabling the APP key"
          onVerifyOtp={(zpin: string, otp: string, setInvalidOTPError: any) => {
            handleEnablingAPIKeyWithZpin(zpin, otp, setInvalidOTPError);
          }}
          onCancel={() => setToggleOtpVerificationForEnableAPIKey(false)}
          handleZpinAuth={handleEnablingAPIKeyWithZpin}
          updateZpinStatus={() => setZpinExists(true)}
        />
      )}
    </div>
  );
}

export default AppInfo;
