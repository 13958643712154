import React from "react";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import Styles from "../../Shared/sharedStyles.module.scss";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import { checkPAN } from "../../../../../utils/validation";
import { IdsGenerator } from "../../../../../utils/idGenerator";

const errorObject = {
  customerPanNumberError: false,
  consentError: false,
};

const errorMessages = {
  customerPanNumberError: "*Please enter a valid Pan number",
  consentError: "*Please provide consent!",
};

const PanToTanPopup = ({ toastTimeout, handleResponse, name }: any) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState(errorObject);

  const panNumberRef = React.useRef<any>("");

  React.useEffect(() => {
    if (panNumberRef.current.value === "") return;

    if (!validatePanNumber()) {
      setErrors(() => ({
        ...errors,
        customerPanNumberError: true,
      }));
      setIsDisabled(true);
      return;
    }

    setIsDisabled(false);
  }, [panNumberRef.current.value, isChecked]);

  const validatePanNumber = () => {
    if (checkPAN(panNumberRef.current.value.trim().toUpperCase())) {
      setErrors(() => ({
        ...errors,
        customerPanNumberError: false,
      }));

      return true;
    }

    setErrors(() => ({
      ...errors,
      customerPanNumberError: true,
    }));
    return false;
  };

  const handleCheckConsent = (e: any) => {
    if (e.target.checked === false) {
      setIsChecked(false);

      setErrors(() => ({
        ...errors,
        consentError: true,
      }));
    } else {
      setIsChecked(true);

      setErrors(() => ({
        ...errors,
        consentError: false,
      }));
    }
  };

  function handleClick() {
    if (panNumberRef.current.value === "" || errors.customerPanNumberError) {
      setErrors(() => ({
        ...errors,
        customerPanNumberError: true,
      }));
      setIsDisabled(true);
      return;
    } else if (!isChecked || errorObject.consentError) {
      setErrors(() => ({
        ...errors,
        consentError: true,
      }));
      setIsDisabled(true);
      return;
    }

    // Request Formation
    const customerPanNumber = panNumberRef.current.value.toUpperCase();
    const consent = isChecked === true ? "Y" : "N";

    setLoading(true);
    handleResponse({ customerPanNumber, consent }, setLoading);
  }

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 position-relative">
            <label className={`${Styles.label} flex-grow-1`}>PAN Number</label>
            <InputField
              id={IdsGenerator({
                prefix: "pan to tan",
                id: "change",
                sufix: "pan number",
              })}
              onChange={validatePanNumber}
              inputRef={panNumberRef}
              maxLength={10}
              isError={errors.customerPanNumberError}
              purpose="forPan"
            />

            <div
              className="position-absolute"
              style={{ bottom: -27, left: 0, width: "max-content" }}
            >
              {errors.customerPanNumberError && (
                <ErrorMessage>{`${errorMessages.customerPanNumberError}`}</ErrorMessage>
              )}
            </div>
          </div>

          <div className="pl-4 pb-4 d-flex flex-column position-relative">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  handleCheckConsent(e);
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            <div className="position-absolute" style={{ bottom: 12, left: 0 }}>
              {errors.consentError && (
                <ErrorMessage>{`${errorMessages.consentError}`}</ErrorMessage>
              )}
            </div>
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-pan"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                handleClick();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanToTanPopup;
