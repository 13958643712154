// Hooks
import React, { useRef } from "react";

// Library Utilities
import { Link } from "react-router-dom";

// Styles
import Styles from "./multipurposeCard.module.scss";

// Constants
import BargraphSVG from "../../../../Shared/svg/bargraphSVG";
import RupeeSVGnew from "../../../../Shared/svg/rupeeSVGnew";
import SubscriptionSVG from "../../../../Shared/svg/subscriptionSVG";
import TickSVGnew from "../../../../Shared/svg/tickSVGnew";

// Functions
import { roundDecimalWithComma } from "../../../../utils/updateAmpunt";

function MultipurposeCard(props: any) {
  const tranxnVolumeRef = useRef(null);
  return (
    <div className={`${Styles.card} ml-1 mb-2 mr-1 p-4`}>
      {props.purpose === "trans_info" && (
        <div className="d-flex flex-column">
          <div className="mb-3">
            <div className="d-flex justify-content-between mb-2">
              <BargraphSVG />
            </div>
            <p className={Styles.cardTitle}>Transaction Volume</p>
            <p ref={tranxnVolumeRef} className={Styles.boldTitle}>
              {props.tranxnVolume}
            </p>
          </div>
          <Link
            to="/application/reports"
            className={
              "card_button d-flex justify-content-center align-items-center"
            }
            id="z-link-to-report"
          >
            View Reports
          </Link>
        </div>
      )}
      {props.purpose === "subscription_info" && (
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="mb-4">
              <div className="d-flex justify-content-between mb-2">
                <SubscriptionSVG />
              </div>
              <p className={Styles.cardTitle}>Products Subscribed</p>
              <p className={Styles.boldTitle}>{props.productCount}</p>
            </div>
            <Link
              to="/application/product-catalog"
              className={
                "card_button d-flex justify-content-center align-items-center"
              }
              id="z-link-product-catalog"
            >
              More Products
            </Link>
          </div>
        </div>
      )}
      {props.purpose === "avl_balance_info" && (
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="mb-3">
              <div className="d-flex justify-content-between mb-2">
                <RupeeSVGnew />
              </div>
              <p className={Styles.cardTitle}>Available Balance</p>
              <p className={Styles.boldTitle}>
                {isNaN(props.availableBalance)
                  ? "NA"
                  : roundDecimalWithComma(props.availableBalance)}
              </p>
            </div>
            <Link
              id="id-add-balance-button"
              to={"/application/billing"}
              className="card_button"
            >
              View Plan
            </Link>
          </div>
        </div>
      )}
      {props.purpose === "trial_info" && (
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="mb-2">
              <div className="d-flex justify-content-between">
                <TickSVGnew />
              </div>
              <p className={Styles.cardTitle}>Trial Approved</p>
              <div className={`${Styles.boldTitleLong} mb-3`}>
                {props.latestApprovedProd}
              </div>
            </div>
            <Link
              to="/application/trial-center"
              className={
                "card_button d-flex justify-content-center align-items-center"
              }
              id="z-link-to-trialcenter"
            >
              Begin Trial
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default MultipurposeCard;
